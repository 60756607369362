const getTimeDiffInMs = (lastScanTime: string): number => {
  const providedDate = new Date(lastScanTime).getTime();
  const currentDate = new Date().getTime(); // Current date and time

  const diffInMilliseconds = currentDate - providedDate;

  return diffInMilliseconds;
};

export const getColorBasedOnTime = (lastScanTime: string) => {
  const diffInMilliseconds = getTimeDiffInMs(lastScanTime);

  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  if (diffInDays < 2) return 'green'; // Less than 2 days
  if (diffInDays < 5) return 'orange'; // Less than 5 days
  return 'red'; // 5 days or more
};

export const calculateTimeSinceLastScan = (lastScanTime: string): string => {
  const diffInMilliseconds = getTimeDiffInMs(lastScanTime);

  // Convert to different time units
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  const timeDifference = `
    ${diffInDays} Days,
    ${diffInHours % 24} Hours,
    ${diffInMinutes % 60} Minutes,
    ${diffInSeconds % 60} Seconds,
    `;

  return `Scanned ${timeDifference} ago`;
};
