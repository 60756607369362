import { AwsAccountScanStatus, AwsResourceConfiguration, scanStatusWithDate } from '@amzn/alkimia-model';
import {
  DeploymentStage,
  getStage,
  LIST_OF_REGIONS,
  getScan, getScanWithPolling, scanAwsAccount } from '@amzn/alkimia-react-components';
import {
  Alert,
  Box,
  Button,
  Container,
  FormField,
  Header,
  Input,
  Link,
  ProgressBar,
  Select,
  SpaceBetween,
  Spinner,
  TextContent,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import {
  OptionDefinition,
} from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getAwsAccountId } from '../../api/calls/getAwsAccountId';
import { getAwsAccountName } from '../../api/calls/getAwsAccountName';
import { listAwsAccounts } from '../../api/calls/listAwsAccounts';
import { initialStateAwsResourceConfiguration } from '../../initalStates';
import { calculateTimeSinceLastScan, getColorBasedOnTime } from './alkimiaHomeUtils';

type AlkimiaHomeProps = {
  accountLabel: string,
  setAccountLabel: React.Dispatch<React.SetStateAction<string>>,
  setRegionID: React.Dispatch<React.SetStateAction<string>>,
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>,
  setListResources: React.Dispatch<React.SetStateAction<AwsResourceConfiguration[]>>,
  token: CognitoToken,
}

type SelectedOption = {
  label: string;
  value: string;
}

function AlkimiaHomeComponent({
  token,
  accountLabel,
  setAccountLabel,
  setRegionID,
  setErrorMessages,
  setListResources,
}: AlkimiaHomeProps) {
  // data
  const [scannedMetaData, setScannedMetaData] = useState<{ services: AwsResourceConfiguration[], accScanStatus: scanStatusWithDate }>({
    services: initialStateAwsResourceConfiguration,
    accScanStatus: { scanStatus: '', scanDate: '' },
  });

  // loading states
  const [isProcessing, setIsProcessing] = useState(false);
  const [loading, setLoading] = useState(true);

  // user selection changes
  const [regionOptions, setregionOptions] = useState<SelectedOption[]>(LIST_OF_REGIONS);
  const [selectedOption, setSelectedOption] = useState<SelectedOption>();
  const [selectedRegion, setSelectedRegion] = useState<SelectedOption>({
    label: 'Please select an AWS Region',
    value: '',
  });
  const [toggleAccountInput, setToggleAccountInput] = useState(false);
  const [typedAccountId, setTypedAccountId] = useState('');
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [region, setRegion] = useState('');
  const [options, setOptions] = useState<SelectedOption[]>([{
    label: '',
    value: '',
  }]);

  // blocked msgs
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockMsg, setBlockMsg] = useState('');
  const cannotFetchBlockedMsg = `Cannot fetch information of the AWS account. Please follow User Guide to grant Alkimia access first.${toggleAccountInput ? ' And make sure the account exists.' : ''}`;

  // misc
  const [percentScanned, setPercentScanned] = useState(0);
  const [progressDescription, setProgressDescription] = useState('');
  const progressIntervalId = useRef<ReturnType<typeof setInterval>>();
  const history = useHistory();

  useEffect(() => {
    const getAccounts = async () => {
      try {
        const option = await listAwsAccounts(token)
          .then((accountList) => (
            (accountList.map((account) => (
              {
                label: `${account.name}`,
                value: `${account.name}`,
              }
            ))
            )));
        setOptions(option);
        setLoading(false);
      } catch (e: any) {
        setIsBlocked(true);
        setBlockMsg('Can not fetch list of AWS accounts. Please refresh and try again.');
      }
    };
    getAccounts();
  }, []);

  useEffect(() => () => {
    clearInterval(progressIntervalId.current);
  }, []);

  const startProgress = useCallback(() => {
    progressIntervalId.current = setInterval(() => {
      // Pseudo logarithmic progress
      // 50% at 30seconds, 80% at 60seconds, 100% at 90 seconds, then restart at 50
      setPercentScanned((prev) => {
        let newPercent = 0;
        if (prev < 50) {
          newPercent = prev + 1.66;
        } else if (prev < 80) {
          newPercent = prev + 1;
        } else if (prev < 98) {
          newPercent = prev + 0.66;
        } else {
          newPercent = 50;
        }
        return newPercent;
      });
    }, 1000);
  }, [progressIntervalId]);

  const resetProcessingStates = () => {
    // Reset states
    setScannedMetaData({
      services: initialStateAwsResourceConfiguration,
      accScanStatus: { scanStatus: '', scanDate: '' },
    });
    setIsProcessing(false);
    setPercentScanned(0);
    setIsBlocked(false);
  };

  const handleSelectChange = async (
    selectedValue: string,
    isAccountSelect: boolean,
  ) => {
    // selection use-cases permutations
    const accountToUse = isAccountSelect ? selectedValue : selectedAccountName;
    const regionToUse = isAccountSelect ? region : selectedValue;
    let blocked = false;
    let blockedMsg = '';
    resetProcessingStates();

    const canScan = isAccountSelect ? region !== '' : selectedAccountName !== '';
    if (!canScan) return;

    setLoading(true);
    try {
      const selectedAccountId = await getAwsAccountId(token, accountToUse);
      const { services, accScanStatus, messages } = await getScan(token, selectedAccountId, regionToUse);
      setAccountLabel(`${selectedAccountId}:${accountToUse}`);

      // process scanned metadata
      if (accScanStatus && accScanStatus.scanStatus) {
        setScannedMetaData({ services, accScanStatus });
      }

      // a successful getScan should never return an undefined accScanStatus
      // if its undefined, it should be a 4xx or 5xx and we need to set appropriate errors
      if (!accScanStatus || accScanStatus!.scanStatus === AwsAccountScanStatus.denied) {
        blocked = true;
        [blockedMsg] = messages;
        setErrorMessages(messages);
        setIsBlocked(true);
        setBlockMsg(blockedMsg);
      }

      // only run path if there's a current scan in-progress
      // run scanAwsAccount which will keep checking status
      const scanAlreadyInProgress = accScanStatus!.scanStatus === AwsAccountScanStatus.pending;
      if (scanAlreadyInProgress && !blocked) {
        setIsProcessing(true);

        try {
          startProgress();
          setProgressDescription('Scanning account resources');
          const { services, messages } = await getScanWithPolling(token, selectedAccountId, regionToUse);

          setListResources(services);
          setErrorMessages(messages);
          if (!services || services.length === 0) {
            blocked = true;
            [blockedMsg] = messages;
          }
        } catch (e) {
          if (!blocked) {
            blocked = true;
            blockedMsg = cannotFetchBlockedMsg;
          }
        }

        setPercentScanned(0);
        if (blocked) {
          setIsBlocked(true);
          setBlockMsg(blockedMsg);
          clearInterval(progressIntervalId.current);
        } else {
          history.push('/resource-selection');
        }
      }
    } catch (error) {
      setIsBlocked(true);
    }
    setLoading(false);
    setIsProcessing(false);
  };

  return (
    <div id="alkimia-home-container">
      {getStage() !== DeploymentStage.PROD
        && (
          <Alert type="warning">
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <div style={{ flex: 1 }}>
                <b>
                  This environment is for development and integration. Please use the stable
                  production website at
                </b>
                {' '}
                <a href="https://prod.alkimia.tools.amazon.dev/">prod.alkimia.tools.amazon.dev</a>
              </div>
              <div>
                <Button
                  ariaLabel="Feedback"
                  iconAlign="right"
                  href="https://sim.amazon.com/issues/create?template=da0d26a2-abe4-439e-9f67-324ea1b57b01"
                  variant="link"
                  iconName="external"
                  target="_blank"
                >
                  Provide Feedback
                </Button>
              </div>
            </div>
          </Alert>
        )}
      <Box fontWeight="normal">
        Region Expansion Accelerators (REXA)
      </Box>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <div style={{ flex: 1 }}>
          <Box variant="h1" fontWeight="bold" fontSize="display-l">
            Alkimia
          </Box>
        </div>
        {getStage() === DeploymentStage.PROD && (
          <div>
            <Button
              ariaLabel="Feedback"
              iconAlign="right"
              href="https://sim.amazon.com/issues/create?template=da0d26a2-abe4-439e-9f67-324ea1b57b01"
              variant="link"
              iconName="external"
              target="_blank"
            >
              Provide Feedback
            </Button>
          </div>
        )}
      </div>
      <Box variant="p" fontWeight="normal">
        An AWS resource exporter that discover the resources in an AWS account and generate the
        Cloud Development Kit (CDK) infrastructure-as-code (IaC) assets that describe them.
      </Box>
      <SpaceBetween size="s">
        <Container
          header={(
            <Header
              variant="h2"
              description=""
            >
              Get Started
            </Header>
          )}
        >
          <SpaceBetween size="s">
            <TextContent>
              Please first grant Alkimia access manually following instructions in below link,
              then select the AWS account to get started.
            </TextContent>
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href="https://w.amazon.com/bin/view/Alkimia/GrantAlkimiaAccessManually"
            >
              Grant Alkimia Access
            </Link>
            <FormField label="">
              <Toggle
                onChange={({ detail }) => setToggleAccountInput(detail.checked)}
                checked={toggleAccountInput}
              >
                Type Account ID manually
              </Toggle>
              <SpaceBetween direction="horizontal" size="s">
                <div id="select-account">
                  {toggleAccountInput === true
                    ? (
                      <Input
                        onChange={({ detail }) => {
                          setTypedAccountId(detail.value);
                        }}
                        value={typedAccountId}
                        placeholder="Type AWS account ID"
                      />
                    )
                    : (
                      <Select
                        selectedOption={selectedOption as OptionDefinition}
                        onChange={async ({ detail: { selectedOption } }) => {
                          setSelectedOption(selectedOption as SelectedOption);
                          setSelectedAccountName(selectedOption.value as string);
                          await handleSelectChange(selectedOption.value as string, true);
                        }}
                        options={options}
                        placeholder="Select the AWS account from which to export resources to CDK"
                        filteringType="auto"
                        disabled={loading}
                      />
                    )}
                </div>
                <div id="select-region">
                  <Select
                    selectedOption={selectedRegion as OptionDefinition}
                    onChange={async ({ detail: { selectedOption } }) => {
                      setSelectedRegion(selectedOption as SelectedOption);
                      setRegion(selectedOption.value as string);
                      setRegionID(selectedOption.value as string);
                      await handleSelectChange(selectedOption.value as string, false);
                    }}
                    placeholder="Select the AWS Region"
                    options={regionOptions}
                    filteringType="auto"
                    disabled={loading}
                  />
                </div>
                <Button
                  disabled={loading || isProcessing || selectedAccountName === '' || selectedRegion.value === ''}
                  variant="primary"
                  onClick={async (event) => {
                    setIsProcessing(true);
                    setIsBlocked(false);
                    setBlockMsg('');
                    event.preventDefault();
                    startProgress();
                    setProgressDescription('Retrieving account details');
                    let blocked = false;
                    let blockedMsg = '';
                    try {
                      let accountId = '';
                      if (toggleAccountInput) {
                        try {
                          const typedAccountName = await getAwsAccountName(token, typedAccountId);
                          typedAccountName.length > 0 ? setAccountLabel(`${typedAccountId}:${typedAccountName}`)
                            : setAccountLabel(`${typedAccountId}`);
                          accountId = typedAccountId;
                        } catch (e: any) {
                          blocked = true;
                          blockedMsg = `${e}`;
                        }
                      } else {
                        const selectedAccountId = await getAwsAccountId(token, selectedAccountName);
                        setAccountLabel(`${selectedAccountId}:${selectedAccountName}`);
                        accountId = selectedAccountId;
                      }
                      setProgressDescription('Scanning account resources');
                      const {
                        services,
                        messages,
                      } = await scanAwsAccount(token, accountId, region);
                      setListResources(services);
                      setErrorMessages(messages);
                      if (!services || services.length === 0) {
                        blocked = true;
                        [blockedMsg] = messages;
                      }
                    } catch (e: any) {
                      if (!blocked) {
                        blocked = true;
                        blockedMsg = cannotFetchBlockedMsg;
                      }
                    }
                    setIsProcessing(false);
                    setPercentScanned(0);
                    if (blocked) {
                      setIsBlocked(true);
                      setBlockMsg(blockedMsg);
                      clearInterval(progressIntervalId.current);
                    } else {
                      history.push('/resource-selection');
                    }
                  }}
                >
                  Start
                </Button>
                <div data-testid="spinner">
                  {loading === true
                    ? <Spinner size="normal" /> : ''}
                </div>
                <div>
                  {
                    scannedMetaData!.accScanStatus.scanStatus === AwsAccountScanStatus.done
                    && (
                      <div>
                        <Button
                          disabled={loading || isProcessing}
                          onClick={() => {
                            setListResources(scannedMetaData!.services);
                            history.push('/resource-selection');
                          }}
                        >
                          Display Previous Scanned Data
                        </Button>
                        <h3 style={{ color: getColorBasedOnTime(scannedMetaData?.accScanStatus.scanDate as string) }}>
                          {`${calculateTimeSinceLastScan(scannedMetaData?.accScanStatus.scanDate as string)}`}
                        </h3>
                        <h3>
                          {`TimeStamp: ${scannedMetaData?.accScanStatus.scanDate}`}
                        </h3>
                      </div>
                    )
                  }
                  {
                    scannedMetaData!.accScanStatus.scanStatus === AwsAccountScanStatus.pending
                    && (
                      <h3>
                        {`Scan is currently ${scannedMetaData?.accScanStatus.scanStatus} and with timestamp ${scannedMetaData?.accScanStatus.scanDate}`}
                      </h3>
                    )
                  }
                  {
                    scannedMetaData!.accScanStatus.scanStatus === AwsAccountScanStatus.none
                    && (
                      <h3>
                        {`No scanned found in account ${selectedAccountName} in region ${selectedRegion.label}. Click start to kick off a new scan`}
                      </h3>
                    )
                  }
                </div>
              </SpaceBetween>
              <div className="pad-t-10" data-testid="progress-bar">
                {isProcessing === true
                  ? (
                    <ProgressBar
                      value={percentScanned}
                      additionalInfo="Scans may take up to 15 minutes for accounts with thousands of resources"
                      description={progressDescription}
                      label={`Scanning account ${accountLabel}`}
                    />
                  ) : ''}
              </div>
            </FormField>
            <Alert visible={isBlocked} type="error">
              {`${blockMsg}`}
            </Alert>
          </SpaceBetween>
        </Container>
        <Container
          header={(
            <Header variant="h2" description="Watch the demo video and follow User Guide.">
              How Alkimia works
            </Header>
          )}
        >
          <SpaceBetween direction="vertical" size="s">
            <iframe
              title="Alkimia demo"
              src="https://broadcast.amazon.com/embed/669725"
              width="525"
              height="300"
              allowFullScreen
            />
            <SpaceBetween direction="horizontal" size="s">
              <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href="https://w.amazon.com/bin/view/Alkimia/UserGuide/"
              >
                User Guide
              </Link>
            </SpaceBetween>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </div>
  );
}

export const AlkimiaHomeComponentAsyncTest = AlkimiaHomeComponent;
